export const productMediaMetaobject = `query getMetaobjectById($id: ID!) {
  metaobject(id: $id) {
    id
    fields {
      key
      value
      references (first: 9) {
        edges {
          node {
            ... on MediaImage {
              image {
                altText
                height
                id
                url
                width
              }
              mediaContentType
            }
            ... on Video {
              mediaContentType
              previewImage {
                altText
                height
                id
                url
                width
              }
              sources {
                format
                height
                mimeType
                url
                width
              }
            }
          }
        }
      }
    }
  }
}`;

export const getCustomerAccessTokenWithMultipass = `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
  customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const getCustomerByAccessToken = `query getCustomerByAccessToken ($accessToken: String!) {
  customer(customerAccessToken: "$accessToken") {
    id
    firstName
    lastName
    acceptsMarketing
    email
    tags
  }
}`;
