import React from 'react';

export function CartItemSubscriptionDropdown(props) {
  const { selectedOption, setSelectedOption, options, updateSubscription } =
    props;

  const handleSelectChange = (event) => {
    const newSelectedOption = options.find(
      (option) => option.sellingPlan.id === event.target.value
    );
    setSelectedOption(newSelectedOption);
    updateSubscription(newSelectedOption);
  };

  const compareSellingPlans = (a, b) => {
    const frequencyA = a.sellingPlan.options[1].value.split('-')[0];
    const frequencyB = b.sellingPlan.options[1].value.split('-')[0];
    return frequencyA - frequencyB;
  };

  return (
    <div className="relative text-primary before:pointer-events-none before:absolute before:right-2 before:top-0 before:scale-x-75 before:scale-y-[0.40] before:content-['▲'] after:pointer-events-none after:absolute after:bottom-[0] after:right-2 after:scale-x-75 after:scale-y-[0.4] after:content-['▼'] ">
      <div className="border-rounded rounded border  p-[2px] pb-[4px] pr-[6px]">
        <select
          className="w-full appearance-none pl-2 pr-4 text-[14px] font-light"
          onChange={(event) => handleSelectChange(event)}
          value={selectedOption?.sellingPlan?.id}
        >
          {[...options].sort(compareSellingPlans).map((option) => {
            return (
              <option value={option.sellingPlan.id} key={option.sellingPlan.id}>
                {option.sellingPlan.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
