import { ContactFormContent } from './ContactFormContent';
import { GorgiasForm } from './GorgiasForm';
import { Schema } from './ContactFormBuilder.schema';
import { ContactForm } from './ContactForm';

export function ContactFormBuilder({ cms }) {
  const {
    heading,
    subHeading,
    fields,
    liveChat,
    callUs,
    smsText,
    commonQuestions,
    submitText,
    formType,
    gorgiasFormUid,
    recaptchaEnabled,
  } = cms;

  const formUid = gorgiasFormUid ?? 'kjxn3ybv';
  const formEnabled = true;

  return (
    <div className="bg-clearBlue">
      <div className="px-contained py-contained flex flex-col-reverse lg:flex-row">
        {formEnabled && (
          <div className="lg:w-1/2 lg:pr-8">
            {heading && (
              <h5 className="mb-2 text-3xl font-light tracking-[-.01em]">
                {heading}
              </h5>
            )}

            {subHeading && (
              <p
                className={`mb-4 text-lg text-[#3383C5] md:mb-6
                ${formType === 'custom' && 'lg:mb-12'}`}
              >
                {subHeading}
              </p>
            )}

            {formType === 'custom' && (
              <ContactForm
                fields={fields}
                submitText={submitText}
                recaptchaEnabled={recaptchaEnabled}
              />
            )}
            {formType === 'gorgias' && formUid && (
              <GorgiasForm formUid={formUid} />
            )}
          </div>
        )}
        <div className={`lg:w-1/2 lg:pl-8 ${!formEnabled ? 'mx-auto' : ''}`}>
          <ContactFormContent content={liveChat} />
          <ContactFormContent content={callUs} />
          <ContactFormContent content={smsText} />
          <ContactFormContent content={commonQuestions} />
        </div>
      </div>
    </div>
  );
}

ContactFormBuilder.displayName = 'ContactFormBuilder';
ContactFormBuilder.Schema = Schema;
