import snakeCase from 'lodash.snakecase';

const inputField = (type) => ({
  label: type,
  key: snakeCase(type),
  defaultItem: {
    name: snakeCase(type),
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
});

const multiChoiceField = (type) => ({
  label: type,
  key: snakeCase(type),
  defaultItem: {
    name: snakeCase(type),
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    ...(type === 'Select'
      ? [
          {
            label: 'Placeholder',
            name: 'placeholder',
            component: 'text',
          },
        ]
      : []),
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Options',
      name: 'options',
      component: 'list',
      field: { component: 'text' },
    },
    ...(type === 'Radio' || type === 'Multiple Checkbox'
      ? [
          {
            label: 'Direction',
            name: 'direction',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Horizontal', value: 'horizontal' },
              { label: 'Vertical', value: 'vertical' },
            ],
            defaultValue: 'horizontal',
          },
        ]
      : []),
    ...(type !== 'Multiple Checkbox'
      ? [
          {
            label: 'Required',
            name: 'required',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ]
      : []),
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    ...(type === 'Multiple Checkbox'
      ? [
          {
            label: 'Hide Label',
            name: 'hideLabel',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ]
      : []),
  ],
});

const countryField = {
  label: 'Country',
  key: 'country',
  defaultItem: {
    name: 'country',
    label: 'Country',
  },
  itemProps: (item) => ({
    label: `Country${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const labelField = {
  label: 'Label',
  key: 'label',
  defaultItem: {
    label: 'Label Text',
  },
  itemProps: (item) => ({
    label: `Label${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
  ],
};

const checkboxField = {
  label: 'Checkbox',
  key: 'checkbox',
  defaultItem: {
    name: 'checkbox',
    label: 'Checkbox',
  },
  itemProps: (item) => ({
    label: `Checkbox${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const fileField = {
  label: 'File',
  key: 'file',
  defaultItem: {
    name: 'file',
    label: 'File Upload',
  },
  itemProps: (item) => ({
    label: `File${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Name',
      name: 'name',
      component: 'text',
      validate: {
        required: true,
      },
      description:
        'Unique, lowercase, and no spaces\ne.g. "email", "first_name", "accepts_marketing"',
    },
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export const Schema = () => {
  return {
    category: 'Text',
    label: 'Contact Form Builder',
    key: 'contact-form-builder',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Questions? Comments? Feedback?',
      },
      {
        label: 'Sub Heading',
        name: 'subHeading',
        component: 'text',
        defaultValue: 'Email us using the form below.',
      },
      {
        label: 'Form Type',
        name: 'formType',
        component: 'radio-group',
        options: [
          { label: 'Gorgias', value: 'gorgias' },
          { label: 'Custom', value: 'custom' },
        ],
        defaultValue: 'gorgias',
      },
      {
        label: 'Gorgias Form UID',
        name: 'gorgiasFormUid',
        component: 'text',
        description: 'Only used if form type is "Gorgias"',
        defaultValue: 'kjxn3ybv',
      },
      {
        label: 'Fields',
        name: 'fields',
        component: 'blocks',
        templates: {
          checkbox: checkboxField,
          country: countryField,
          date: inputField('Date'),
          email: inputField('Email'),
          file: fileField,
          label: labelField,
          multipleCheckbox: multiChoiceField('Multiple Checkbox'),
          number: inputField('Number'),
          phone: inputField('Phone'),
          radio: multiChoiceField('Radio'),
          select: multiChoiceField('Select'),
          text: inputField('Text'),
          textArea: inputField('Text Area'),
          time: inputField('Time'),
          url: inputField('Url'),
        },
      },
      {
        label: 'Submit Button Text',
        name: 'submitText',
        component: 'text',
        defaultValue: 'Submit',
      },
      {
        label: 'reCAPTCHA v2 Enabled',
        name: 'recaptchaEnabled',
        component: 'toggle',
        description:
          'reCAPTCHA v2 site key must first be set as environment variable',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Live Chat',
        name: 'liveChat',
        component: 'group',
        description: 'Live Chat Section',
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
        ],
        defaultValue: {
          title: 'Live Chat',
        },
      },
      {
        label: 'Call Us',
        name: 'callUs',
        component: 'group',
        description: 'Call Us Section',
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
        ],
        defaultValue: {
          title: 'Call Us',
        },
      },
      {
        label: 'SMS Text',
        name: 'smsText',
        component: 'group',
        description: 'SMS Text Section',
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
        ],
      },
      {
        label: 'Common Questions',
        name: 'commonQuestions',
        component: 'group',
        description: 'Common Questions Section',
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
        ],
      },
    ],
  };
};
