import {
  getCustomerAccessTokenWithMultipass as queryCustomerAccessTokenWithMultipass,
  getCustomerByAccessToken as queryCustomerByAccessToken,
} from '../gql';
import { queryStorefrontApi } from '../utils/queryStorefrontApi';

export function useAuth() {
  const getCustomerAccessTokenWithMultipass = async (multipassToken) => {
    try {
      const query = queryCustomerAccessTokenWithMultipass;
      const variables = {
        multipassToken,
      };
      const res = await queryStorefrontApi(query, variables);
      const data = res?.body;

      if (!data?.customerAccessTokenCreateWithMultipass) {
        throw new Error('No data returned from Shopify');
      }

      return {
        status: res.status,
        body: data.customerAccessTokenCreateWithMultipass,
      };
    } catch (error) {
      console.error('Error:', error);
      return {
        status: 500,
        error: 'Error receiving data',
      };
    }
  };

  const getCustomerByAccessToken = async (accessToken) => {
    try {
      const query = queryCustomerByAccessToken;
      const variables = {
        accessToken,
      };
      const res = await queryStorefrontApi(query, variables);
      const data = res?.body;

      if (!data?.customer) {
        throw new Error('No data returned from Shopify');
      }

      return {
        status: res.status,
        body: data.customer,
      };
    } catch (error) {
      console.error('Error:', error);
      return {
        status: 500,
        error: 'Error receiving data',
      };
    }
  };

  return { getCustomerAccessTokenWithMultipass, getCustomerByAccessToken };
}
