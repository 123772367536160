import { useProduct } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';

import { Image } from '../Image';
import { Markdown } from '../Markdown';
import { Svg } from '../Svg';
import { formatCurrency } from '../../utils/formatCurrency';

export function RechargeSubscriptionWidgetDetails({
  selectedVariant,
  selectedSellingPlan,
  shipsNow,
  shipsRecurringly,
  className,
  widgetType,
  item,
  expandable = false,
}) {
  const product = useProduct();
  const [shipNowImage, setShipNowImage] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (selectedVariant?.image?.src) {
      setShipNowImage(selectedVariant?.image?.src);
    } else if (shipsNow?.image?.src) {
      setShipNowImage(shipsNow?.image?.src);
    } else if (product?.featuredImage?.src) {
      setShipNowImage(product?.featuredImage?.src);
    }
  }, [product, selectedVariant, shipsNow]);

  const frequencyDisplay = selectedSellingPlan?.sellingPlan?.name?.replace(
    'Delivery every ',
    ''
  );

  return (
    <div className={`p-4 text-primary lg:pl-12 ${className}`}>
      {expandable && (
        <button
          type="button"
          className="-m-4 flex w-[calc(100%+2rem)] items-center justify-between border-t-[#DDE2E7] bg-[#F2F7FB] p-4"
          onClick={() => {
            setShow(!show);
          }}
        >
          Details
          <svg viewBox="0 0 24 24" className="w-4 text-current">
            <title>Plus</title>
            <use
              href={show ? '/svgs/minus.svg#minus' : '/svgs/plus.svg#plus'}
              className="pointer-events-none"
            />
          </svg>
        </button>
      )}
      {(!expandable || show) && (
        <>
          {shipsNow?.show && (
            <div className={`ships-now ${expandable ? 'mt-8' : ''}`}>
              <h3 className="mb-4 flex border-b border-[#20263533] pb-4 text-sm font-light uppercase leading-6">
                <Svg
                  className="mr-2 w-[17px] text-text"
                  src="/svgs/box.svg#box"
                  title="Recurring Shipping"
                  viewBox="0 0 17 17"
                />
                Ships Now
              </h3>
              <div className="flex items-center text-[14px] [&_li]:mb-0">
                {shipNowImage && (
                  <div>
                    <div className="relative aspect-square h-[63px] w-[63px] md:h-[85px] md:w-[85px]">
                      <Image
                        alt="Ships Now"
                        className="absolute h-full w-full object-contain !mix-blend-multiply"
                        width="60px"
                        height="60px"
                        src={shipNowImage}
                      />
                    </div>
                  </div>
                )}

                {shipsNow?.items && (
                  <div className="text-[14px] font-light [&_li]:!mb-0 [&_p]:!text-[14px]">
                    {item?.quantity && shipsNow?.items && (
                      <Markdown>
                        {shipsNow?.items.replaceAll('{qty}', item?.quantity)}
                      </Markdown>
                    )}
                    {!item?.quantity && shipsNow?.items && (
                      <Markdown>
                        {shipsNow?.items.replaceAll('{qty}', 1)}
                      </Markdown>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {shipsRecurringly.show && (
            <div className="ships-recurringly mt-4">
              <div className="mb-4 flex justify-between border-b border-[#20263533] py-4">
                <h3 className="mr-4 flex text-sm font-light uppercase leading-6">
                  <Svg
                    className="mr-2 w-[15px] text-text"
                    src="/svgs/recurring.svg#recurring"
                    title="Recurring Shipping"
                    viewBox="0 0 15 14"
                  />
                  {` Ships every ${frequencyDisplay}`}
                </h3>
                {widgetType === 'featured' && (
                  <div>
                    <span className="text-shadowGray line-through">
                      {formatCurrency(shipsRecurringly?.price)}
                    </span>
                    <span className="ml-4">
                      {formatCurrency(shipsRecurringly?.overridePrice)}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex items-center text-[14px] font-light [&_li]:!mb-0">
                {shipsRecurringly?.image?.src && (
                  <div>
                    <div className="relative aspect-square h-[63px] w-[63px] md:h-[85px] md:w-[85px]">
                      <Image
                        alt="Autoships"
                        className="absolute h-full w-full object-contain !mix-blend-multiply"
                        width="60px"
                        height="60px"
                        src={shipsRecurringly?.image?.src}
                      />
                    </div>
                  </div>
                )}
                <div className="text-[14px] font-light [&_li]:!mb-0 [&_p]:!text-[14px]">
                  {item?.quantity && shipsRecurringly?.items && (
                    <Markdown>
                      {shipsRecurringly?.items.replaceAll(
                        '{qty}',
                        item?.quantity
                      )}
                    </Markdown>
                  )}
                  {!item?.quantity && shipsRecurringly?.items && (
                    <Markdown>
                      {shipsRecurringly?.items.replaceAll('{qty}', 1)}
                    </Markdown>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
