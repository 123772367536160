import { useEffect, useRef } from 'react';

export function GorgiasForm({ formUid }) {
  const elemRef = useRef(null);

  useEffect(() => {
    if (!elemRef.current) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://contact.gorgias.help/api/contact-forms/loader.js?v=3';
    script.defer = true;
    script.setAttribute('data-gorgias-loader-contact-form', '');
    script.setAttribute('data-gorgias-contact-form-uid', formUid);
    elemRef.current.appendChild(script);
  }, []);

  return <div ref={elemRef}>{/* Render script here */}</div>;
}
