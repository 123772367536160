export function Schema() {
  return {
    key: 'product-additional-details',
    label: 'Product Additional Details',
    category: 'Product',
    fields: [
      {
        component: 'group-list',
        name: 'productCallouts',
        label: 'Product Callouts',
        itemProps: {
          label: 'Callout: {{item.title}}',
        },
        fields: [
          {
            component: 'text',
            name: 'title',
            label: 'Title',
          },
          {
            component: 'text',
            name: 'content',
            label: 'Content',
          },
          {
            component: 'image',
            name: 'icon',
            label: 'Icon',
          },
        ],
        defaultValue: [
          {
            title: 'Extended Filter Life',
            content: 'Lasts 2.5x longer than standard store-bought filters',
          },
          {
            title: 'Affinity® Filtration',
            content:
              'Targets up to 73x more contaminants than mainstream water filters',
          },
          {
            title: 'Medical-Grade Materials',
            content: '100% free of BPS/BPA, phthalates, and EA activity',
          },
        ],
        validate: {
          maxItems: 3,
        },
      },
    ],
  };
}
