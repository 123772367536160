import { useEffect, useRef, useState, useCallback, Fragment } from 'react';

import Head from 'next/head';

import { FormField } from './FormField';
import { useForm } from './useForm';
import { Button } from '../../snippets';

const isBrowser = typeof window !== 'undefined';

export function ContactForm(cms) {
  const formRef = useRef(null);
  const captchaRef = useRef(null);

  const { fields, submitText, recaptchaEnabled } = cms;

  const { parsedFields } = useForm({ fields });

  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);

  const renderCaptcha = isBrowser && window?.grecaptcha?.render;
  const captchaReady = typeof renderCaptcha === 'function';
  const isRecaptchaEnabled =
    recaptchaEnabled && !!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

  const submitGorgiasForm = async (payload) => {
    const apiEndpoint = '/api/gorgias';

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!data) {
      throw new Error('No data returned from fetchReviewAggregate');
    }

    return data;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        setErrors([]);
        setSuccessMessage(null);

        const formIsValid = formRef.current?.checkValidity();

        // if (!isRecaptchaEnabled || !captchaLoaded || !formIsValid) return;
        if ((isRecaptchaEnabled && !captchaLoaded) || !formIsValid) return;

        if (isRecaptchaEnabled) {
          const captchaResponse =
            isBrowser && (await window?.grecaptcha?.getResponse());

          if (!captchaResponse) {
            setErrors(['Please verify you are not a robot']);
            return;
          }
        }

        const payload = Object.fromEntries(new FormData(formRef.current));
        const res = await submitGorgiasForm(payload);

        if (res.error) {
          setErrors([res.error]);
        } else {
          formRef.current.reset();
          setSuccessMessage('Message sent successfully!');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [isRecaptchaEnabled, captchaLoaded]
  );

  // Render captcha if recaptcha is enabled and captcha is ready
  // Must add NEXT_PUBLIC_RECAPTCHA_SITE_KEY to env variables
  // See: https://developers.google.com/recaptcha/intro
  useEffect(() => {
    try {
      if (!captchaReady || !isRecaptchaEnabled) return;
      renderCaptcha('form-captcha-widget', {
        sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      });
    } catch (error) {
      console.error(error);
    }
  }, [captchaReady, isRecaptchaEnabled]);

  // Observe when captcha is loaded to permit form submission
  useEffect(() => {
    if (!captchaRef.current || !isRecaptchaEnabled) return undefined;
    const observer = new MutationObserver(() => {
      setCaptchaLoaded(true);
      observer.disconnect();
    });
    observer.observe(captchaRef.current, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [isRecaptchaEnabled]);

  return (
    <div>
      <Head>
        <script src="https://www.google.com/recaptcha/api.js" />
      </Head>

      <form
        className="grid grid-cols-2 gap-8 [&_input]:font-extralight [&_textarea]:font-extralight"
        encType="multipart/form-data"
        method="POST"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        {parsedFields?.map((field) => (
          <Fragment key={field.name}>
            <FormField field={field} />
          </Fragment>
        ))}

        {fields?.some((field) => field.required) && (
          <div className="col-span-2 m-0 block text-xs opacity-50">
            * Required field
          </div>
        )}

        <div className="col-span-2 flex flex-col gap-4">
          {isRecaptchaEnabled && (
            <div ref={captchaRef} className="my-4">
              <div id="form-captcha-widget" />
            </div>
          )}

          <Button
            theme="secondary"
            variant="outlined"
            type="submit"
            className="mt-6 flex w-full max-w-[210px] items-center justify-center bg-[#fff]"
          >
            {submitText || 'Submit'}
          </Button>

          {errors?.length > 0 && (
            <div className="flex flex-col gap-1">
              {errors.map((error) => (
                <p key={error} className="text-red-500">
                  {error}
                </p>
              ))}
            </div>
          )}

          {successMessage && (
            <p className="rounded border border-solid border-green-700 bg-green-50 p-3 text-center text-green-700">
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}
