const endpoint = `https://clearly-filtered.myshopify.com/api/${process.env.SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`;

export async function queryStorefrontApi(query, variables = {}) {
  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.SHOPIFY_STOREFRONT_API_TOKEN,
      },
      body: { query } && JSON.stringify({ query, variables }),
    });

    const data = await result.json();

    if (!data?.data) {
      throw new Error('No data returned from Shopify');
    }

    return {
      status: result.status,
      body: data.data,
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      status: 500,
      error: 'Error receiving data',
    };
  }
}
